import {createTheme} from "@mui/material";

const theme = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: "1.1rem",
                    lineHeight: "1.2em",
                    height: "2.4em"
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    justifyContent: "space-between"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(230, 230, 230, 0.7)"
                }
            }
        }
    }
});

export default theme;