import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import SearchInput from "./search/SearchInput";
import SearchHits from "./hits/SearchHits";
import SearchStore, {SearchStoreContext} from "./state/SearchStore";
import {Box, CssBaseline, styled, ThemeProvider} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import SearchAppBar from "./app-bar/SearchAppBar";
import theme from "./theme";

const AppContainer = styled(Box)(({theme}) => ({
    padding: theme.spacing(2)
}));

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <SearchAppBar/>
            <AppContainer>
                <SearchStoreContext.Provider value={new SearchStore()}>
                    <Grid2 md={12} display="flex" justifyContent="center" alignContent="center" sx={{
                        padding: '50px'
                    }}>
                        <SearchInput/>
                    </Grid2>
                    <Grid2 container
                           rowSpacing={{xs: 2, sm: 3, md: 4, lg: 6}}
                           columnSpacing={{xs: 2, sm: 3, md: 4, lg: 6}}
                           columns={{xs: 1, sm: 2, md: 3, lg: 4}}>
                        <SearchHits/>
                    </Grid2>
                </SearchStoreContext.Provider>
            </AppContainer>
        </ThemeProvider>
    );
}

export default App;
