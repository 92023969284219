import {MeiliSearch} from "meilisearch";
import isDev from "./process";

const MEILISEARCH_CONFIG = isDev()
    ? {
        host: 'https://dev-search.pogryzki.pl',
        apiKey: '14a6b372fd50cebf90514bd2bdf98d4628b0592c23380d0db42ef0a7765d70e6'
    }
    : {
        host: 'https://search.pogryzki.pl',
        apiKey: '196265b49793522781eaaa4d7d2e403efa9b9d7f3262340e4a25db988b2cd191'
    }

class SearchService {

    private client;

    private recipesIndex;

    constructor() {
        this.client = new MeiliSearch(MEILISEARCH_CONFIG);
        this.recipesIndex = this.client.index('recipes');
    }

    search(query: string) {
        return this.recipesIndex.search(query);
    }
}

export default SearchService;