import React, {useContext} from 'react';
import {Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Rating, Typography} from "@mui/material";
import {SearchStoreContext} from "../state/SearchStore";
import {observer} from "mobx-react";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Hit} from "meilisearch";
import {NoPhotographyRounded} from "@mui/icons-material";
import {grey} from "@mui/material/colors";

const normalizeRating = (hit: Hit) => hit.rating / ((hit.ratingMax || 5) / 5);

const abbreviate = (text: string, length: number) => {
    if (!text || text.length <= length) {
        return text;
    }
    return text.substring(0, length - 3) + "…";
}

function HitImage({src, title, pageIcon, pageTitle}: { src: string, title: string, pageIcon: string, pageTitle: string }) {
    if (src) {
        return (
            <div style={{position: "relative"}}>
                <CardMedia component="img" height="200" image={src} alt={title}/>
                <Chip avatar={<Avatar src={pageIcon} />} label={pageTitle} sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px"
                }}/>
            </div>
        );
    } else {
        return (
            <div style={{position: "relative"}}>
                <Box height="200px" display="flex" alignItems="center" justifyContent="center" sx={{
                    backgroundColor: grey["300"]
                }}>
                    <NoPhotographyRounded color="disabled" sx={{fontSize: 60}}/>
                </Box>
                <Chip avatar={<Avatar src={pageIcon} />} label={pageTitle} sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px"
                }}/>
            </div>
        );
    }
}

function SearchHits() {
    const searchStore = useContext(SearchStoreContext)

    if (searchStore.hits.length === 0) {
        return (
            <Grid2 xs={1} sm={2} md={3} lg={4} display="flex" justifyContent="center" alignContent="center"
                   textAlign="center">
                ...
            </Grid2>
        )
    }

    return (
        <React.Fragment>
            {searchStore.hits.map((hit) => (
                <Grid2 key={hit.id} xs={1} display="flex" justifyContent="center" alignContent="center">
                    <Card sx={{width: '100%'}}>
                        <HitImage src={hit.image} title={hit.title} pageIcon={hit.pageIcon} pageTitle={hit.pageTitle}/>
                        <CardContent sx={{
                            maxHeight: "400px",
                            flexGrow: 1
                        }}>
                            <Typography variant="h6">
                                {abbreviate(hit.title, 70)}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {abbreviate(hit.description, 130)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            {hit.rating
                                ? <Box display="flex" alignItems="center">
                                    <Rating value={normalizeRating(hit)} precision={0.1} readOnly/>
                                    <Typography variant="body2" color="text.secondary">
                                        {normalizeRating(hit)} ({hit.ratingCount})
                                    </Typography>
                                </Box>
                                : <Box/>
                            }
                            <Button size="small" href={hit.url}>
                                Otwórz
                            </Button>
                        </CardActions>
                    </Card>
                </Grid2>
            ))}
        </React.Fragment>
    );
}


export default observer(SearchHits);