import React, {FormEvent, useContext, useState} from 'react';
import {IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {SearchStoreContext} from "../state/SearchStore";

function SearchInput() {
    const [query, setQuery] = useState('');
    const searchStore = useContext(SearchStoreContext)

    const handleQueryChange = (e: React.KeyboardEvent<HTMLInputElement> & {
        target: HTMLInputElement
    }) => {
        setQuery(e.target.value);
        e.stopPropagation();
    }

    const handleSubmit = (e: FormEvent) => {
        searchStore.fetchResults(query);
        e.preventDefault();
    }

    return (
        <Paper
            component="form" onSubmit={handleSubmit}
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Co chcesz przyrządzić?"
                inputProps={{ 'aria-label': 'Co chcesz przyrządzić?' }}
                value={query}
                onChange={handleQueryChange}
            />
        </Paper>
    );
}

export default SearchInput;