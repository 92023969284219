import {Hit, SearchResponse} from "meilisearch";
import {makeAutoObservable} from "mobx";
import SearchService from "../services/SearchService";
import {createContext} from "react";

const ZERO_STATE = {
    hits: new Array<Hit>(),
    offset: 0,
    limit: 9,
    totalHits: 0,
    query: '',
    isFetching: false,
    isError: false
}

class SearchStore {

    searchService: SearchService;
    hits: Array<Hit> = ZERO_STATE.hits;
    offset: number | undefined = ZERO_STATE.offset;
    limit: number | undefined = ZERO_STATE.limit;
    totalHits: number | undefined = ZERO_STATE.totalHits;
    query: string = ZERO_STATE.query;
    isFetching: boolean = ZERO_STATE.isFetching;
    isError: boolean = ZERO_STATE.isError;

    constructor() {
        makeAutoObservable(this);
        this.searchService = new SearchService()
    }

    fetchResults(query: string) {
        this.isFetching = true;
        this.isError = false;
        this.searchService.search(query).then(this.fetchResultsSuccess, this.fetchResultsError);
    }

    fetchResultsSuccess = (searchResponse: SearchResponse) => {
        this.isFetching = false;
        this.isError = false;
        this.hits = searchResponse.hits;
        this.query = searchResponse.query;
        this.limit = searchResponse.limit;
        this.offset = searchResponse.offset;
        this.totalHits = searchResponse.totalHits;
    }

    fetchResultsError = (error: Error) => {
        this.isFetching = false;
        this.isError = true;
        console.log(error);
    }
}

export const SearchStoreContext = createContext(new SearchStore())

export default SearchStore;